/* ==============
  Form-Advanced
===================*/
/* Datepicker */
.datepicker {
  border: 1px solid $lightgray;
  padding: 8px;
}

.datepicker table tr td.active, .datepicker table tr td.active:hover, .datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover,.datepicker table tr td.today, .datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover, .datepicker table tr td.today:hover,.datepicker table tr td.selected,
.datepicker table tr td.selected.disabled, .datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected:hover  {
  background-color: $primary !important;
  background-image: none;
  box-shadow: none;
  color: $white;
}

.table-condensed{
  thead th, tbody td{
      padding: 5px;
  }
}

/* Bootstrap-touchSpin */
.bootstrap-touchspin .input-group-btn-vertical .btn {
  padding: 9px 12px;
}
.bootstrap-touchspin .input-group-btn-vertical i {
  top: 4px;
  left: 8px;
}
.input-group-addon {
  padding: .375rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
}

/* Prism */
:not(pre) > code[class*="language-"], pre[class*="language-"] {
  background: $light;
}

/* Rating */
.badge:empty {
  padding: 0;
}

