
/* ==============
    footer
===================*/

.footer {
  background-color: $white;
  font-size: 13px;
  bottom: 0;
  color: $dark;
  text-align: center;
  padding: 20px 0px;
  position: absolute;
  right: 0;
  left: 0px;
}