/* ==============
  Form-elements
===================*/

label {
  font-weight: 600;
}

.form-control {
  font-size: $base-font;
  &:focus {
    border-color: $primary;
    box-shadow: none;
  }
}

.custom-control-input:checked~.custom-control-indicator {
  background-color: $primary;
}

.custom-control-input:focus~.custom-control-indicator {
  -webkit-box-shadow: 0 0 0 1px $white, 0 0 0 3px $primary;
  box-shadow: 0 0 0 1px $white, 0 0 0 3px $primary;
}

.has-success {
  .form-control {
    border-color: $success;
    box-shadow: none;
  }
}

.has-warning {
  .form-control {
    border-color: $warning;
    box-shadow: none;
  }
}

.has-danger {
  .form-control {
    border-color: $danger;
    box-shadow: none;
  }
}

.input-group-addon {
  border-radius: 2px;
  border: 1px solid $light;
}