
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');

// Color variables
$white: #ffffff;

$primary: #5985ee;
$success: #46cd93;
$info: #4bbbce;
$warning: #fdba45;
$danger: #f24734;
$purple: #a462e0;
$pink: #e66793;

$dark: #0f1f3e;
$muted: #707070;

$lightdark5: #555555;
$lightgray: #dddddd;
$light: #eff3f6;
$lightdark3: #333333;
$lightdark4: #444444;
$light5: #f5f5f5;
$light3: #f3f3f3;
$light9: #f9f9f9;
$light-alt: #fafafa;
$lightdark7: #797979;
$lightdark-alt: #cccccc;

$bg-body: #f5f5f5;
$bg-topbar: #424858;

$shadow: 0 0 0.25rem rgba(108,118,134,.1);

$base-font: 13px;

$font-primary: 'Open Sans', sans-serif;

// Width variables
$width: 100%;


// Height variables
$height: 100%;
