/* ==============
  Helper Classes
===================*/

.p-0 {
  padding: 0;
}

.p-t-10 {
  padding-top: 10px;
}
.p-b-10 {
  padding-bottom: 10px;
}
.m-0 {
  margin: 0;
}
.m-r-5 {
  margin-right: 5px;
}
.m-r-10 {
  margin-right: 10px;
}
.m-r-15 {
  margin-right: 15px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-t-40 {
  margin-top: 40px;
}

.m-b-5 {
  margin-bottom: 5px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.w-xs {
  min-width: 80px;
}
.w-sm {
  min-width: 95px;
}
.w-md {
  min-width: 110px;
}
.w-lg {
  min-width: 140px;
}
.m-h-50 {
  min-height: 50px;
}
.l-h-34 {
  line-height: 34px;
}

.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-32 {
  font-size: 32px;
}
.font-40 {
  font-size: 40px;
}
.no-border {
  border: none;
}

.thumb-sm {
  height: 32px;
  width: 32px;
}
.thumb-md {
  height: 48px;
  width: 48px;
}
.thumb-lg {
  height: 88px;
  width: 88px;
}
.grid-structure {
  .grid-container {
    background-color: $light5;
    margin-bottom: 10px;
    padding: 10px 20px;
  }
}

.font-600 {
  font-weight: 600;
}

.header-title {
  margin-bottom: 8px;
  letter-spacing: 0.02em;
  font-size: 14px;
  text-transform: uppercase;
}

// rating 
.rating-symbol-foreground{
  top: 0px;
}

@media (max-width: 768px) {
  .mo-mb-2 {
    margin-bottom: 10px !important;
  }

  .mo-mt-2 {
    margin-top: 10px !important;
  }
}