@import "~bootstrap/scss/bootstrap";
body {
  .language-selector {
    position: fixed;
    left: 0;
    bottom: 0;
    color: #ffffff !important;
    background-color: #424858;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 5px;
    text-decoration: none;
    z-index: 999;
    hr {
      margin: 5px 0 5px 0;
      color: #ffffff;
    }
    a:visited {
      color: #ffffff;
      text-decoration: none;
    }
  }
}