/* ==============
  Account Pages
===================*/

.account-pages{
  margin: 10.5% auto;
  position: relative;
  .logo-admin{
    line-height: 70px;
  }
  .user-thumb {
    img{
      height: 88px;
      width: 88px;
    }
  }
}

.home-btn{
    position: absolute;
    top: 8px;
    right: 12px;
    z-index: 1;
}

.ex-page-content {
  h1 {
    font-size: 98px;
    font-weight: 500;
    line-height: 150px;
    text-shadow: rgba($primary, 0.3) 1px 1px, rgba($primary, 0.2) 2px 2px, rgba($primary, 0.3) 3px 3px;
    i{
      font-size: 84px;
    }
  }
}

/* ==============
  pricing
=================*/

.pricing-box{
  box-shadow: 0 0 0.25rem rgba(108, 118, 134, 0.1);
  border-radius: 7px;
  margin-bottom: 30px;
  .pricing-title{
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }
}

/* ==============
  faqs
=================*/

.faq-box{
  position: relative;
  overflow: hidden;
  .faq-icon{
    .icon-one{
      position: absolute;
      right: -5px;
      top: -12px;
      width: 116px;
      height: 94px;
      text-align: center;
      line-height: 96px;
      background: rgba($primary, 0.1);
      color: rgba($primary, 0.2);
      border-radius: 50px;
    }
    .icon-two{
      position: absolute;
      right: 74px;
      top: -38px;
      width: 116px;
      height: 94px;
      text-align: center;
      line-height: 96px;
      background: rgba($pink, 0.1);
      color: rgba($pink, 0.2);
      border-radius: 50px;
    }
  }
}

/* ==============
  comming soon
=================*/

.comming-watch{
  div {
      display: inline-block;
      .card{
          margin: 0px 15px 15px 15px;
          .countdown-num
          {
              font-weight: 600;
              color: $primary;
          }
      }
      span {
          width: 150px;
          display: block;
          &:first-child {
              height: 60px;
              font-weight: 300;
              font-size: 3em;
              line-height: 48px;
          }
          &:last-child {
              padding-top: 14px;
              font-size: 0.9em;
          }
      }        
  }
}


