/* === LOADER === */

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $primary;
    z-index: 9999999;
    #status {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%);
        .spinner {
            margin: 100px auto;
            width: 50px;
            height: 40px;
            text-align: center;
            font-size: 10px;
             > div {
                background-color: $white;
                height: 100%;
                width: 6px;
                display: inline-block;
                -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
                animation: sk-stretchdelay 1.2s infinite ease-in-out;
            }

            .rect2 {
                -webkit-animation-delay: -1.1s;
                animation-delay: -1.1s;
            }
              
            .rect3 {
                -webkit-animation-delay: -1.0s;
                animation-delay: -1.0s;
            }
              
            .rect4 {
                -webkit-animation-delay: -0.9s;
                animation-delay: -0.9s;
            }
              
            .rect5 {
                -webkit-animation-delay: -0.8s;
                animation-delay: -0.8s;
            }
        }
    }
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}