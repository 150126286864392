/* ==============
    page title
===================*/

.wrapper {
    margin-top: 100px;
}

.page-title-box {
    padding: 24px 0px;
    color: $dark;
    .page-title {
        font-size: 16px;
        text-transform: uppercase;
    }
    .dropdown-menu{
        &.show{
            margin-top: 10px !important;
            &:before{
              content: "";
              position: absolute;
              width: 12px;
              height: 12px;
              background: $white;
              top: -6px;
              left: 18px;
              transform: rotate(45deg);
              box-shadow: -2px -2px 0.25rem rgba(108, 118, 134, 0.1);
            }
        }
    }
    .dropdown-menu-right{
        &.show{
            &:before{
              right: 24px;
              left: auto;
            }
        }
    }
}