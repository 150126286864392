/* ==============
  Timeline
===================*/

.cd-container {
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
}
.cd-container::after {
  content: '';
  display: table;
  clear: both;
}
#cd-timeline {
  margin-bottom: 2em;
  margin-top: 2em;
  padding: 2em 0;
  position: relative;
  &::before {
    border-left: 3px solid $white;
    content: '';
    height: 100%;
    left: 26px;
    position: absolute;
    top: 0;
    width: 3px;
  }
}

.cd-timeline-content{
  background: $white;
}

@media only screen and (min-width: 769px) {
  #cd-timeline {
    margin-bottom: 3em;
    margin-top: 3em;
    &::before {
      left: 50%;
      margin-left: -2px;
    }
  }
}

.cd-timeline-block {
  margin: 2em 0;
  position: relative;
  &:after {
    clear: both;
    content: "";
    display: table;
  }
}
.cd-timeline-block:first-child {
  margin-top: 0;
}
.cd-timeline-block:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: px) {
  .cd-timeline-block {
    margin: 4em 0;
  }
  .cd-timeline-block:first-child {
    margin-top: 0;
  }
  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }
}

.cd-timeline-content {
  border-radius: 5px;
  border: 1px solid $light;
  position: relative;

  &:after {
    clear: both;
    content: "";
    display: table;
  }
  h2 {
    margin-top: 0;
  }
  .cd-read-more {
    background: $primary;
    border-radius: 0.25em;
    color: $white;
    display: inline-block;
    float: right;
    font-size: 14px;
    padding: .8em 1em;
  }
  .cd-date {
    display: inline-block;
    font-size: 14px;
  }
  h3 {
    font-size: 16px;
    margin: 0 0 15px 0;
  }
}

.no-touch .cd-timeline-content .cd-read-more:hover {
  background-color: #bac4cb;
}
.cd-timeline-content .cd-date {
  padding-top: .8em;
  opacity: .7;
}
@media only screen and (min-width: 769px) {
  .cd-timeline-content {
    width: 54%;
  }
  .cd-timeline-content .cd-read-more {
    float: left;
  }
  .cd-timeline-content .cd-date {
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content {
    float: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: $light;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
    float: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
    left: auto;
    right: 122%;
    text-align: right;
  }

}

@media (max-width:991px){
  .cd-timeline-content{
    overflow: hidden;
  }
}

