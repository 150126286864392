/*
 Template Name: Zinzer - Responsive Bootstrap 4 Admin Dashboard
 Author: ThemeDesign
 File: Icons
 */

@import "../icons/font-awesome/scss/fontawesome";
@import "../icons/material-design/materialdesignicons";
@import "../icons/ionicons/scss/ionicons.min";
@import "../icons/themify-icons/themify-icons";
@import "../icons/dripicons/dripicons";
@import "../icons/typicons/typicons";
